module.exports = [
    {
        "id": 1,
        "latitude": 50.99070390840007,
        "longitude": 34.862731339208736,
        "elevation": 197
    },
    {
        "id": 2,
        "latitude": 50.99070390840007,
        "longitude": 34.86632697140874,
        "elevation": 196
    },
    {
        "id": 3,
        "latitude": 50.99070390840007,
        "longitude": 34.860933523108734,
        "elevation": 193
    },
    {
        "id": 4,
        "latitude": 50.99070390840007,
        "longitude": 34.85913570700873,
        "elevation": 192
    },
    {
        "id": 5,
        "latitude": 50.98890609230007,
        "longitude": 34.860933523108734,
        "elevation": 190
    },
    {
        "id": 6,
        "latitude": 50.97632137960006,
        "longitude": 34.850146626508725,
        "elevation": 188
    },
    {
        "id": 7,
        "latitude": 50.99070390840007,
        "longitude": 34.873518235808746,
        "elevation": 187
    },
    {
        "id": 8,
        "latitude": 50.97632137960006,
        "longitude": 34.84655099430872,
        "elevation": 186
    },
];