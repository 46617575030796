import { useState, useEffect } from 'react';
import { fetcherAsync } from '../helpers/fetcherAsync';
import { configApiLinks } from '../config/configApiLinks';
import { config } from '../config/config';
import axios from 'axios';

export const useOpenElevation = () => {
    const [elevationMarkers, setElevationMarkers] = useState([]);
    const [stepMeters, setStepMeters] = useState(100);
    const [stepTargetMeters, setStepTargetMeters] = useState(100);
    const [topLimit, setTopLimit] = useState(1000);
    useEffect(() => {

    }, []);

    const getHighestElevationInRectangleArea = async (rectangleArray) => {
        let curElevationMarkers;
        setElevationMarkers([]);
        if (!rectangleArray) {
            return;
        }
        console.log('rectangleArray', rectangleArray);
        console.log('rectangleArray[0][0].LatLng', rectangleArray[0][0].lat);
        const reqBody = {
            leftTop: {
                latitude: rectangleArray[0][0].lat,
                longitude: rectangleArray[0][0].lng

            },
            rightTop: {
                latitude: rectangleArray[0][1].lat,
                longitude: rectangleArray[0][1].lng

            },
            rightBottom: {
                latitude: rectangleArray[0][2].lat,
                longitude: rectangleArray[0][2].lng

            },
            leftBottom: {
                latitude: rectangleArray[0][3].lat,
                longitude: rectangleArray[0][3].lng

            },
            stepMeters: stepMeters,
            topCount: topLimit

        };
        console.log({ reqBody });
        // try {
        //     curElevationMarkers = await fetcherAsync(
        //         configApiLinks.getHighestElevationInRectangleArea,
        //         'POST',
        //         reqBody,
        //         true
        //     );
        //     console.log('curElevationMarkers', curElevationMarkers.data);
        //     setElevationMarkers(curElevationMarkers);
        // } catch (error) {
        //     //curElevationMarkers = { ...elevationMarkers };
        // }
        try {
            const response = await axios.post(
                `${config.apiUrl}${configApiLinks.getHighestElevationInRectangleArea}`,
                reqBody,
                { headers: { 'Content-Type': 'application/json' } }
            );
            curElevationMarkers = response.data;
            setElevationMarkers(curElevationMarkers);
        } catch (error) {
            console.log('ERR_SFR_OPEN_SHIFT', error.message);
            // responseData = {};
            // responseData.is_error = true;
            // responseData.error = error.message;
        }
    };

    const getElevationOfPoint = async (lat, lng) => {
        let elevationOfPoint;
        try {
            const response = await axios.post(
                `${config.apiUrl}${configApiLinks.getElevation}`,
                {
                    locations: [
                        {
                            latitude: lat,
                            longitude: lng
                        }
                    ]
                },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if (response.data && response.data.results && response.data.results.length) {
                elevationOfPoint = response.data.results[0].elevation;
            }
        } catch (error) {
            console.log('ERR_getElevationOfPoint', error.message);
            // responseData = {};
            // responseData.is_error = true;
            // responseData.error = error.message;
        }
        return elevationOfPoint;
    }

    const getTargetViewData = async (latStart, lngStart, latEnd, lngEnd) => {
        let targetViewData = {};
        console.log('getTargetViewData->stepTargetMeters', stepTargetMeters);
        try {
            const response = await axios.post(
                `${config.apiUrl}${configApiLinks.getTargetViewData}`,
                {
                    latStart, lngStart, latEnd, lngEnd, stepMeters: stepTargetMeters
                },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if (response.data) {
                targetViewData = response.data;
            }
        } catch (error) {
            console.log('ERR_getTargetViewData', error.message);
            // responseData = {};
            // responseData.is_error = true;
            // responseData.error = error.message;
        }
        return targetViewData;
    }

    return [
        elevationMarkers,
        getHighestElevationInRectangleArea,
        stepMeters,
        setStepMeters,
        topLimit,
        setTopLimit,
        getElevationOfPoint,
        getTargetViewData,
        stepTargetMeters,
        setStepTargetMeters
    ];
};
