import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import EditIcon from '@mui/icons-material/Edit';
// import Divider from '@mui/material/Divider';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Container, CssBaseline, Grid, Box, Divider, Drawer, IconButton, AppBar, Toolbar, Typography, Link, TextField, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import classes from './elevationButtonTableModal.module.css';
import iconMountion from '../../images/icon-mountains.svg';
import UpdateIcon from '@mui/icons-material/Update';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export default function ElevationButtonTableModal(props) {
  const [showModal, setShowModal] = useState(true);
  const handleClick = (event) => {
    setShowModal(!showModal);
  };
  const handleClose = () => {
  };

  const tabColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'elevation',
      headerName: 'elevation',
      type: 'number',
      width: 90,
    },
    { field: 'gmaps', headerName: 'gmaps', width: 130 },
    { field: 'latitude', headerName: 'latitude', width: 130 },
    { field: 'longitude', headerName: 'longitude', width: 130 },
  ];

  return (
    <React.Fragment>
      <div className={classes.dialogWrap}>
        <Fab
          size="medium"
          onClick={handleClick}
        >
          <img
            src={iconMountion}
            className={classes.buttonIcon}
            alt='loading...'
          //style={getIconStyles(props)}
          />
        </Fab>
        <div className={`${classes.dataElevParamsWrapper} ${!showModal && classes.hiddenDiv}`}>
          <div className={classes.elevInputParamsWrapper} >
            <div className={classes.elevInputParams}>
              <TextField
                id="step-meters"
                label="Step"
                variant="outlined"
                size="small"
                type="number"
                value={props.stepMeters}
                onChange={(e) => {
                  props.setStepMeters(Number(e.target.value))
                }}
              />
            </div>
            <div className={classes.elevInputParams}>
              <TextField
                id="select-top"
                label="Top"
                variant="outlined"
                size="small"
                type="number"
                value={props.topLimit}
                onChange={(e) => {
                  console.log('onChange topLimit', e.target.value);
                  props.setTopLimit(Number(e.target.value))
                }}
              />
            </div>
            <IconButton aria-label="update" onClick={() => { props.handleRectangleCreationUpdate() }}>
              <UpdateIcon />
            </IconButton>
            <IconButton aria-label="remove" onClick={() => { props.handleRectangleRemove() }}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </div>
        </div>
        <div className={`${classes.dataGridWrapper} ${!showModal && classes.hiddenDiv}`}>
          <DataGrid
            rows={props.elevationMarkers}
            // rows={elevationMarkersDummy}
            columns={tabColumns}
            // pageSize={5}
            rowsPerPageOptions={[15]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => {
              const currentSelectedRows = props.elevationMarkers.filter((cRow) => ids.includes(cRow.id));
              props.setSelectedRows(currentSelectedRows);
            }}
          />
        </div>
      </div>
    </React.Fragment >
  );
}
