import React from 'react';
import './Map.css';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Rectangle,
    FeatureGroup,
    Circle,
    Polyline
} from 'react-leaflet'
import { useMapEvents, useMapEvent, useMap } from 'react-leaflet/hooks'
//import Container from '@mui/material/Container';
import {
    Container,
    CssBaseline,
    Grid,
    Box,
    Divider,
    Drawer,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Link,
    TextField,
    Button
} from '@mui/material';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import { EditControl } from "react-leaflet-draw"
//import 'react-leaflet-draw/dist/leaflets.draw.css';
import L from "leaflet";
import { useOpenElevation } from '../hooks/useOpenElevation';
import { useState, Fragment } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import TylesChangeButtonModal from './controls/tylesChangeButtonModal';
import ElevationButtonTableModal from './controls/elevationButtonTableModal';
import TargetElevationChart from './controls/targetElevationChart';
import elevationMarkersDummy from './dummy/elevationMarkers'
import { useParams, useNavigate } from "react-router-dom"
//delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
    iconUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const MainMap2 = () => {
    const navigate = useNavigate();
    const positionDefault = { lat: 50.90823, lng: 34.79571 };
    const { zoom, lat, lng } = useParams();
    const [positionMap, setPositionMap] = useState({ lat: lat ? lat : positionDefault.lat, lng: lng ? lng : positionDefault.lng });
    const [zoomMap, setZoomMap] = useState(zoom ? zoom : 8);
    const [
        elevationMarkers,
        getHighestElevationInRectangleArea,
        stepMeters,
        setStepMeters,
        topLimit,
        setTopLimit,
        getElevationOfPoint,
        getTargetViewData,
        stepTargetMeters,
        setStepTargetMeters
    ] = useOpenElevation();
    //const drawerWidth = 250;//window.screen.availWidth;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [currentRectangleData, setCurrentRectangleData] = useState(null);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [targetMarker, setTargetMarker] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedTileLayer, setSelectedTileLayer] = useState('osmStd');
    const [targetLine, setTargetLine] = useState({ isActive: false });
    const [activeMarker, setActiveMarker] = useState(null);
    const [chartActiveMarker, setChartActiveMarker] = useState(null);

    const baseMaps = {
        osmStd: {
            name: 'OpenStreatMap',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 24
        },
        openTopo: {
            name: 'OpenTopoMap',
            url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
            attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
            maxZoom: 17,
        },
        googleHybrid: {
            name: 'Google Hybrid',
            url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
            maxZoom: 24,
            subdomains: ['mt1', 'mt2', 'mt3']
        },
        thunderForestLandscape: {
            name: 'Thunderforest Landscape',
            url: 'https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey={apikey}',
            attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            apikey: 'e98a6dff4af8444ca84d39b310f8ca7f',
            maxZoom: 22,
            variant: 'transport-dark',
        },
    }

    function MapEvent(props) {
        const setCurrentMapRoutes = (mapEvents) => {
            const posMap = mapEvents.getCenter();
            const zmMap = mapEvents.getZoom();
            setPositionMap({ lat: posMap.lat, lng: posMap.lng });
            setZoomMap(zmMap);
            props.navigate(`/${zmMap}/${posMap.lat}/${posMap.lng}`, { replace: true });
        }
        const map = useMapEvent({
            //map.setCenter([50.1, 30.1])
            zoomend: () => {
                //console.log('map center:', map.getCenter());
                //console.log('map zoom:', map.getZoom());
                setCurrentMapRoutes(map);
            },
            // locationfound: (location) => {
            //     console.log('location found:', location)
            // },
            moveend: () => {
                //console.log('map center:', map.getCenter());
                //console.log('map zoom:', map.getZoom());
                setCurrentMapRoutes(map);
            },
        })
        return null
    }

    const tileLayerChangeHandler = (selTileLayer) => {
        //console.log('tileLayerChangeHandler', selTileLayer);
        setSelectedTileLayer(selTileLayer);
    }
    //console.log('selectedTileLayer', selectedTileLayer);
    // const rectangle = [
    //     [50.90823, 34.79571],
    //     [50.90923, 34.80771],
    // ];
    // const rectangle = [
    //     [50.917217706339265, 34.80640411376954],
    //     [50.92078843338569, 34.81481552124024],
    // ];
    // const rectangle = [
    //     [50.9116000000000, 34.8213815689087],
    //     [50.9916000000000, 34.8913815689087],
    // ];
    //console.log({ mobileOpen });
    //console.log(baseMaps[selectedTileLayer].url);
    // console.log({ positionMap, zoomMap });
    console.log({ stepMeters, topLimit });

    const handleRectangleCreationUpdate = (rectangleArray = null) => {
        setTargetLine({ isActive: false });
        setChartActiveMarker(false);
        if (rectangleArray) {
            setCurrentRectangleData(rectangleArray);
        }
        const latLngArray = rectangleArray ? rectangleArray : currentRectangleData;
        console.log('handleRectangleCreationUpdate', { latLngArray, rectangleArray, currentRectangleData });
        if (latLngArray) {
            getHighestElevationInRectangleArea(latLngArray);
        }
    }

    const handleMarkerCreationUpdate = async (lat, lng) => {
        const elevation = await getElevationOfPoint(lat, lng);
        console.log('handleMarkerCreationUpdate->elevation', elevation);
        setTargetLine({ isActive: false });
        setChartActiveMarker(false);
        setTargetMarker({ lat, lng, elevation });
    }

    const editControlOnCreated = async (e) => {
        if (e.layerType === "rectangle") {
            console.log('onCreatedRectangle', e);
            console.log('onCreatedRectangle@LatLngs', e.layer.getLatLngs());
            console.log('EditControl->rectangle->onCreated', { stepMeters, topLimit });
            const latLngs = await e.layer.getLatLngs();
            //e.layer._leaflet_id
            e.layer.remove();
            handleRectangleCreationUpdate(latLngs);
        } else if (e.layerType === "marker") {
            console.log('onCreatedMarker', e);
            console.log('onCreatedMarker->_latlng', e.layer._latlng);
            //setTargetMarker({ layer: e.layer, lat: e.layer._latlng.lat, lng: e.layer._latlng.lng });
            handleMarkerCreationUpdate(e.layer._latlng.lat, e.layer._latlng.lng);
            e.layer.remove();
        }
    }

    const handleRectangleRemove = () => {
        setCurrentRectangleData(null);
        getHighestElevationInRectangleArea(null);
        setSelectedRows([]);
        setTargetLine({ isActive: false });
        setChartActiveMarker(false);
    }

    const updateTargetViewData = async (currentMarkerDataParam = null) => {
        const currentMarkerData = currentMarkerDataParam ? currentMarkerDataParam : activeMarker;
        if (targetMarker) {
            const targetViewData = await getTargetViewData(currentMarkerData.lat, currentMarkerData.lng, targetMarker.lat, targetMarker.lng);
            setTargetLine({
                isActive: true,
                data: [[currentMarkerData.lat, currentMarkerData.lng], [targetMarker.lat, targetMarker.lng]],
                distance: targetViewData.distance,
                dataChart: targetViewData.dataChart
            });
        }
    }

    const handleMarkerClick = async (currentMarkerData) => {
        setActiveMarker({ lat: currentMarkerData.lat, lng: currentMarkerData.lng });
        updateTargetViewData(currentMarkerData);
    }

    return (
        <React.Fragment>
            <MapContainer zoom={zoomMap} center={positionMap} key={selectedTileLayer} >
                <MapEvent navigate={navigate} />
                <TileLayer {...baseMaps[selectedTileLayer]} />
                {selectedRows.length > 0 && selectedRows.map((currentRowMarker) => {
                    return (
                        <Marker
                            position={{ lat: currentRowMarker.latitude, lng: currentRowMarker.longitude }}
                            eventHandlers={{
                                click: () => {
                                    handleMarkerClick({ lat: currentRowMarker.latitude, lng: currentRowMarker.longitude });
                                },
                            }}
                        >
                            <Popup>
                                {currentRowMarker.elevation}
                                <p>
                                    <Link href={currentRowMarker.gmaps} target="_blank">google maps</Link>
                                </p>
                            </Popup>
                        </Marker>
                    )
                })}
                {currentRectangleData && <Rectangle bounds={currentRectangleData} />}
                {targetMarker &&
                    <Marker
                        position={{ lat: targetMarker.lat, lng: targetMarker.lng }}
                    >
                        <Popup>
                            {targetMarker.elevation}
                            <p onClick={() => {
                                //targetMarker.layer.remove();
                                setTargetMarker(null);
                                setTargetLine({ isActive: false })
                                setChartActiveMarker(false);
                            }}>
                                {/* <Link href={targetMarker.gmaps} target="_blank">google maps</Link> */}
                                <a href="#">Remove</a>
                            </p>
                        </Popup>
                    </Marker>
                }
                {targetMarker && chartActiveMarker &&
                    <Marker
                        position={{ lat: chartActiveMarker.lat, lng: chartActiveMarker.lng }}
                    >
                        <Popup>
                            {chartActiveMarker.elevation}
                            <p onClick={() => {
                                //targetMarker.layer.remove();
                                setChartActiveMarker(null);
                            }}>
                                {/* <Link href={targetMarker.gmaps} target="_blank">google maps</Link> */}
                                <a href="#">Remove</a>
                            </p>
                        </Popup>
                    </Marker>
                }
                {targetLine.isActive && <Polyline positions={targetLine.data} />}
                <FeatureGroup >
                    <EditControl
                        id='leafletdraw'
                        className='leafletdraw'
                        position='topright'
                        style={{ top: '50px' }}
                        // onEdited={async (e) => {
                        //     console.log('onEdited', e);
                        //     console.log('onEdited@LatLngs', e.target.layer.getLatLngs());
                        //     setCurrentRectangleData(await e.layer.getLatLngs());
                        //     getHighestElevationInRectangleArea(await e.layer.getLatLngs(), stepMeters, topLimit);
                        // }}
                        onCreated={editControlOnCreated}
                        onDeleted={(e) => {
                            console.log('onDeleted', e);
                        }}
                        draw={{
                            //rectangle: false
                            circle: false,
                            circlemarker: false,
                            polygon: false
                        }}
                    />
                </FeatureGroup>
                {/* rectangleArray */}
            </MapContainer>
            <TylesChangeButtonModal
                selectedTileLayer={selectedTileLayer}
                baseMaps={baseMaps}
                onSelect={tileLayerChangeHandler}
            />
            <ElevationButtonTableModal
                elevationMarkers={elevationMarkers}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                stepMeters={stepMeters}
                setStepMeters={setStepMeters}
                topLimit={topLimit}
                setTopLimit={setTopLimit}
                handleRectangleCreationUpdate={handleRectangleCreationUpdate}
                handleRectangleRemove={handleRectangleRemove}
                setTargetLine={setTargetLine}

            />
            {targetLine.isActive &&
                <TargetElevationChart
                    stepTargetMeters={stepTargetMeters}
                    setStepTargetMeters={setStepTargetMeters}
                    targetMarker={targetMarker}
                    targetLine={targetLine}
                    updateTargetViewData={updateTargetViewData}
                    setChartActiveMarker={setChartActiveMarker}
                />
            }
        </React.Fragment>
    );
}

export default MainMap2;