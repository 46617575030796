// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tylesChangeButtonModal_dialogWrap__LHCQD {\n    position: absolute;\n    top: 190px;\n    right: 4px;\n    z-index: 1000;\n    /* background-color: white;\n    border-radius: 4px; */\n}\n\n.tylesChangeButtonModal_buttonIcon__-aPiu {\n    width: 16px;\n    height: 16px;\n    line-height: 16px;\n    margin-left: 0.3em;\n    margin-right: 0.3em;\n    margin-top: 0.0em;\n    margin-bottom: 0.0em;\n}\n\n.tylesChangeButtonModal_listElement__JNgGV label {\n    margin-left: 0px;\n    /* margin-right: 0px; */\n}", "",{"version":3,"sources":["webpack://./src/map/controls/tylesChangeButtonModal.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,aAAa;IACb;yBACqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".dialogWrap {\n    position: absolute;\n    top: 190px;\n    right: 4px;\n    z-index: 1000;\n    /* background-color: white;\n    border-radius: 4px; */\n}\n\n.buttonIcon {\n    width: 16px;\n    height: 16px;\n    line-height: 16px;\n    margin-left: 0.3em;\n    margin-right: 0.3em;\n    margin-top: 0.0em;\n    margin-bottom: 0.0em;\n}\n\n.listElement label {\n    margin-left: 0px;\n    /* margin-right: 0px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogWrap": "tylesChangeButtonModal_dialogWrap__LHCQD",
	"buttonIcon": "tylesChangeButtonModal_buttonIcon__-aPiu",
	"listElement": "tylesChangeButtonModal_listElement__JNgGV"
};
export default ___CSS_LOADER_EXPORT___;
