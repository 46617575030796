// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/leaflet/dist/leaflet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,\n#root,\n.leaflet-container {\n  height: 100vh;\n  width: 100vw;\n  /* height: 100%;\n  width: 100%; */\n}\n\n/* #leafletdraw .leaflet-top {\n  top: 80px;\n} */\n", "",{"version":3,"sources":["webpack://./src/map/Map.css"],"names":[],"mappings":"AAEA;;;EAGE,aAAa;EACb,YAAY;EACZ;gBACc;AAChB;;AAEA;;GAEG","sourcesContent":["@import url('~leaflet/dist/leaflet.css');\n\nbody,\n#root,\n.leaflet-container {\n  height: 100vh;\n  width: 100vw;\n  /* height: 100%;\n  width: 100%; */\n}\n\n/* #leafletdraw .leaflet-top {\n  top: 80px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
