//import logo from './logo.svg';
import './App.css';
//import './Map.css';
//import { MapContainer, TileLayer, useMap, Marker, Popup, Rectangle } from 'react-leaflet'
//import MainMap from './map/mapMain';
import MainMap2 from './map/mapMain2';
import Routing from './Routing';

function App() {
  return (
    //<MainMap2/>
    <Routing/>
  )
}

export default App;
