import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Container, CssBaseline, Grid, Box, Divider, Drawer, IconButton, AppBar, Toolbar, Typography, Link, TextField, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import classes from './targetElevationChart.module.css';
import iconMountion from '../../images/icon-mountains.svg';
import UpdateIcon from '@mui/icons-material/Update';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
//
//import { ResponsiveStream } from '@nivo/stream';
import { ResponsiveLine } from '@nivo/line';

export default function TargetElevationChart({
    stepTargetMeters,
    setStepTargetMeters,
    targetMarker,
    targetLine,
    updateTargetViewData,
    setChartActiveMarker
}) {
    const [showModal, setShowModal] = useState(true);
    const data = [{
        "id": "us",
        "color": "hsl(330, 70%, 50%)",
        "data": [
            {
                "x": 0,
                "y": 322
            },
            {
                "x": 100,
                "y": 37
            },
            {
                "x": 200,
                "y": 146
            },
            {
                "x": 300,
                "y": 174
            },
            {
                "x": 400,
                "y": 276
            },
            {
                "x": 500,
                "y": 35
            },
            {
                "x": 600,
                "y": 44
            },
            {
                "x": 700,
                "y": 107
            },
            {
                "x": 800,
                "y": 90
            },
            {
                "x": 900,
                "y": 267
            },
            {
                "x": 1000,
                "y": 134
            },
            {
                "x": 1100,
                "y": 72
            }
        ]
    }
    ];

    return (
        <React.Fragment>
            <div className={classes.dialogWrap}>
                <div className={classes.elevInputParamsWrapper} >
                    <div className={classes.elevInputParams}>
                        <TextField
                            id="step-meters"
                            label="Step"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={stepTargetMeters}
                            onChange={(e) => {
                                setStepTargetMeters(Number(e.target.value));
                            }}
                        />
                    </div>
                    <IconButton
                        aria-label="update"
                        onClick={() => {
                            updateTargetViewData();
                        }}
                    >
                        <UpdateIcon />
                    </IconButton>
                    <div className={classes.elevInputParams}>
                        <TextField
                            id="select-top"
                            label="Elevation"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={targetMarker.elevation}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className={classes.elevInputParams}>
                        <TextField
                            id="select-top"
                            label="Distance, m"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={targetLine.distance}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    {/* <IconButton aria-label="remove" onClick={() => { props.handleRectangleRemove() }}>
                        <HighlightOffOutlinedIcon />
                    </IconButton> */}
                </div>
                <div className={classes.chartEl}>
                    <ResponsiveLine
                        data={[{
                            id: "us",
                            color: "rgb(240,248,255)",
                            data: targetLine.dataChart
                        }]}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'distance',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'elevation',
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        onClick={(point, event) => {
                            if (point.data.lat && point.data.lng) {
                                setChartActiveMarker({
                                    isActive: true,
                                    lat: point.data.lat,
                                    lng: point.data.lng,
                                    elevation: point.data.y,
                                    distance: point.data.x
                                });
                            } else {
                                setChartActiveMarker(null);
                            }
                            console.log({ point, event });
                        }}
                    />
                </div>
            </div>
        </React.Fragment >
    );
}
