import {
    BrowserRouter as Router,
    Routes,
    Switch,
    Redirect,
    Route
} from "react-router-dom";
import MainMap2 from './map/mapMain2';


const Routing = () => {
    return (
        <Router>
            <Routes>
                {/* <MainMap2 /> */}
                {/* <Route path="/:zoom/:lat/:lng" element={<MainMap2 />} /> */}
                <Route path="/:zoom/:lat/:lng" element={<MainMap2 />} />
                <Route path="/" element={<MainMap2 />} />
                {/* <Route path="/" element={<MainMap2 />} /> */}
            </Routes>
        </Router>
    );
}

export default Routing;