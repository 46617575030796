import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import EditIcon from '@mui/icons-material/Edit';
// import Divider from '@mui/material/Divider';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import classes from './tylesChangeButtonModal.module.css';
import iconMapLayers from '../../images/map-layers.svg';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    position: 'absolute',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function TylesChangeButtonModal(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [clickedShow, setClickedShow] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClickedShow(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBlur = () => {
    //console.log('blur');
    if (clickedShow) {
      //console.log('blur2');
      setAnchorEl(null);
      setClickedShow(false);
    }
  };
  const handleFocus = () => {
    //console.log('focus');
    setClickedShow(true);
  }

  const [selectedValue, setSelectedValue] = useState(props.selectedTileLayer);
  const handleSelectValue = (event) => {
    setSelectedValue(event.target.value);
    props.onSelect(event.target.value);
  };
  return (
    <div className={classes.dialogWrap} >
      <Fab
        size="medium"
        onClick={handleClick}
      >
        <img
          src={iconMapLayers}
          className={classes.buttonIcon}
          alt='loading...'
        //style={getIconStyles(props)}
        />
      </Fab>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedValue}
          onChange={handleSelectValue}
          onMouseOver={handleFocus}
          onBlur={handleBlur}
        >
          {Object.keys(props.baseMaps).map((sValue) => {
            return (
              <React.Fragment key={sValue}>
                <FormControlLabel
                  key={sValue}
                  className={classes.listElement}
                  value={sValue}
                  control={<Radio />}
                  label={props.baseMaps[sValue].name}
                  style={{ marginLeft: '0px' }} />
                {/* <Divider sx={{ my: 0.5 }} /> */}
              </React.Fragment>
            )
          }
          )}
        </RadioGroup>
      </StyledMenu>
    </div>
  );
}
